/***************************************************************************************************
 * BROWSER POLYFILLS
 */

// Add the polyfill at the beginning of the file
import { Buffer } from 'buffer';

// Make Buffer available globally (if necessary)
(window as any).Buffer = Buffer;

